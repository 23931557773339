@import "../styles/mixins";

.logo {
  width: 5rem;
  height: 9.5625rem;

  display: block;

  background: transparent url("/images/app/bakuro-logo-2020.png") no-repeat center center;
  background-size: contain;

  font-size: 0;

  @include lower($mobile) {
    width: 3.2rem;
    height: 6.12rem;
  }
}
