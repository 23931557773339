#bakuro {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

#header {
  position: sticky;
  top: 0;

  flex: 1 0 20em;
}

#gallery {
  flex: 10 0 20em;
}
