@import "../styles/variables";
@import "../styles/mixins";

#contact {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  text-align: center;
  line-height: 1.5;

  @include above($mobile) {
    padding-top: 4em;
  }

  @include below($mobile) {
    padding-bottom: 4em;
  }

  .logo {
    @include lower($mobile) {
      display: none;
    }
  }
}
