*,
*:before,
*:after {
  position: relative;

  margin: 0;
  padding: 0;
  box-sizing: inherit;

  -webkit-font-smoothing: antialiased;
}

html {
  height: 100%;
  /*	overflow-x: hidden;
	overflow-y: scroll;*/
  box-sizing: border-box;

  font-size: 16px;
}

body {
  min-height: 100%;
  /*overflow-x: hidden;*/

  background: white;
}

::-webkit-selection {
  background: $primary;
  color: white;
}

::-moz-selection {
  background: $primary;
  color: white;
}

::selection {
  background: $primary;
  color: white;
}

a {
  color: $primary;
  text-decoration: underline;

  &:hover {
    color: $primary;
  }

  &:active {
    color: black;
  }
}

hr {
  height: 0;
  margin: 2rem 0;

  border: 0;
}

.marked {
  padding: 0 0.25em;

  color: white;
  background-color: $primary;

  font-weight: bold;
  text-align: center;
}
