@import "../../styles/variables";

.grid {
  padding: 1em;

  display: flex;
  flex-wrap: wrap;

  .grid-element {
    flex: 1 0 16em;
    margin: 1em;

    display: block;
  }
}
