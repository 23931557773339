@import "../styles/variables";
@import "../styles/mixins";

#detail {
  padding: 4em;

  display: flex;
  justify-content: center;
  align-items: center;

  @include below($mobile) {
    padding: 1em 1em 4em;
  }

  picture {
    width: 100%;
    height: 100%;

    display: block;
  }

  img {
    width: 100%;
    height: 100%;

    display: block;
    object-fit: contain;
  }

  .caption {
    position: absolute;
    left: 50%;
    right: 6em;
    bottom: 6em;

    width: 50%;
    margin-left: -25%;

    text-align: center;
  }
}
