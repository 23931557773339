@import "../styles/variables";
@import "../styles/mixins";

#gallery {
  z-index: 24;

  background-color: white;

  @include below($mobile) {
    margin-bottom: 25vh;
  }

  figure {
    padding-bottom: 66.667%;

    display: block;

    background-color: $light;

    &:before {
      content: "";

      position: absolute;
      top: 50%;
      left: 50%;

      width: 5em;
      height: 5em;
      margin-top: -2.5em;
      margin-left: -2.5em;

      background: transparent url(/images/app/loader.png) no-repeat center center;
      background-size: 5em auto;
      opacity: 0.5;
      filter: brightness(10) saturate(0);
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;

    display: block;

    opacity: 0;

    transition: opacity 0.5s ease;

    &:not(.loading):not(.error) {
      opacity: 1;
    }

    &.error {
      transition-duration: 0s;
    }
  }

  figcaption {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
