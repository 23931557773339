#header {
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  text-align: center;
  line-height: 1.5;

  h2 {
    font-size: 1.2em;
  }

  p {
    font-size: 0.875em;
  }
}
