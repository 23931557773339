@import "../../styles/variables";
@import "../../styles/mixins";

.viewer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 48;

  height: 100vh;

  background-color: rgba(white, 0.95);

  .close,
  .prev,
  .next {
    padding: 1rem;

    color: $dark;
    opacity: 0.5;

    text-decoration: none;
    font-size: 2em;
    line-height: 1;
    text-align: center;

    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
    }
  }

  .close {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    @include below($mobile) {
      transform: rotate(180deg);
    }
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;

    margin-top: -2rem;

    @include below($mobile) {
      top: 100%;
      margin-top: -4rem;
    }

    @include orientation(portrait) {
      top: 100%;
      margin-top: -4rem;
    }
  }

  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }
}
