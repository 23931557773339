$font: "DejaVu Serif";

@font-face {
  font-family: $font;
  src: url("/fonts/hinted-DejaVuSerif.eot");
  src: local($font), local("DejaVuSerif"), url("/fonts/hinted-DejaVuSerif.eot?#iefix") format("embedded-opentype"),
    url("/fonts/hinted-DejaVuSerif.woff2") format("woff2"), url("/fonts/hinted-DejaVuSerif.woff") format("woff"),
    url("/fonts/hinted-DejaVuSerif.ttf") format("truetype"),
    url("/fonts/hinted-DejaVuSerif.svg#DejaVuSerif") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font;
  src: url("/fonts/hinted-DejaVuSerif-Italic.eot");
  src: local("DejaVu Serif Italic"), local("DejaVuSerif-Italic"),
    url("/fonts/hinted-DejaVuSerif-Italic.eot?#iefix") format("embedded-opentype"),
    url("/fonts/hinted-DejaVuSerif-Italic.woff2") format("woff2"),
    url("/fonts/hinted-DejaVuSerif-Italic.woff") format("woff"),
    url("/fonts/hinted-DejaVuSerif-Italic.ttf") format("truetype"),
    url("/fonts/hinted-DejaVuSerif-Italic.svg#DejaVuSerif-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: $font;
  src: url("/fonts/hinted-DejaVuSerif-Bold.eot");
  src: local("DejaVu Serif Bold"), local("DejaVuSerif-Bold"),
    url("/fonts/hinted-DejaVuSerif-Bold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/hinted-DejaVuSerif-Bold.woff2") format("woff2"),
    url("/fonts/hinted-DejaVuSerif-Bold.woff") format("woff"),
    url("/fonts/hinted-DejaVuSerif-Bold.ttf") format("truetype"),
    url("/fonts/hinted-DejaVuSerif-Bold.svg#DejaVuSerif-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: $font;
  src: url("/fonts/hinted-DejaVuSerif-BoldItalic.eot");
  src: local("DejaVu Serif Bold Italic"), local("DejaVuSerif-BoldItalic"),
    url("/fonts/hinted-DejaVuSerif-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("/fonts/hinted-DejaVuSerif-BoldItalic.woff2") format("woff2"),
    url("/fonts/hinted-DejaVuSerif-BoldItalic.woff") format("woff"),
    url("/fonts/hinted-DejaVuSerif-BoldItalic.ttf") format("truetype"),
    url("/fonts/hinted-DejaVuSerif-BoldItalic.svg#DejaVuSerif-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
}

// @font-face {
//   font-family: "DejaVu Serif Condensed";
//   src: url("/fonts/hinted-DejaVuSerifCondensed.eot");
//   src: local("DejaVu Serif Condensed"), local("DejaVuSerifCondensed"),
//     url("/fonts/hinted-DejaVuSerifCondensed.eot?#iefix") format("embedded-opentype"),
//     url("/fonts/hinted-DejaVuSerifCondensed.woff2") format("woff2"),
//     url("/fonts/hinted-DejaVuSerifCondensed.woff") format("woff"),
//     url("/fonts/hinted-DejaVuSerifCondensed.ttf") format("truetype"),
//     url("/fonts/hinted-DejaVuSerifCondensed.svg#DejaVuSerifCondensed") format("svg");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "DejaVu Serif Condensed";
//   src: url("/fonts/hinted-DejaVuSerifCondensed-BoldItalic.eot");
//   src: local("DejaVu Serif Condensed Bold Italic"), local("DejaVuSerifCondensed-BoldItalic"),
//     url("/fonts/hinted-DejaVuSerifCondensed-BoldItalic.eot?#iefix") format("embedded-opentype"),
//     url("/fonts/hinted-DejaVuSerifCondensed-BoldItalic.woff2") format("woff2"),
//     url("/fonts/hinted-DejaVuSerifCondensed-BoldItalic.woff") format("woff"),
//     url("/fonts/hinted-DejaVuSerifCondensed-BoldItalic.ttf") format("truetype"),
//     url("/fonts/hinted-DejaVuSerifCondensed-BoldItalic.svg#DejaVuSerifCondensed-BoldItalic") format("svg");
//   font-weight: bold;
//   font-style: italic;
// }

// @font-face {
//   font-family: "DejaVu Serif Condensed";
//   src: url("/fonts/hinted-DejaVuSerifCondensed-Bold.eot");
//   src: local("DejaVu Serif Condensed Bold"), local("DejaVuSerifCondensed-Bold"),
//     url("/fonts/hinted-DejaVuSerifCondensed-Bold.eot?#iefix") format("embedded-opentype"),
//     url("/fonts/hinted-DejaVuSerifCondensed-Bold.woff2") format("woff2"),
//     url("/fonts/hinted-DejaVuSerifCondensed-Bold.woff") format("woff"),
//     url("/fonts/hinted-DejaVuSerifCondensed-Bold.ttf") format("truetype"),
//     url("/fonts/hinted-DejaVuSerifCondensed-Bold.svg#DejaVuSerifCondensed-Bold") format("svg");
//   font-weight: bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: "DejaVu Serif Condensed";
//   src: url("/fonts/hinted-DejaVuSerifCondensed-Italic.eot");
//   src: local("DejaVu Serif Condensed Italic"), local("DejaVuSerifCondensed-Italic"),
//     url("/fonts/hinted-DejaVuSerifCondensed-Italic.eot?#iefix") format("embedded-opentype"),
//     url("/fonts/hinted-DejaVuSerifCondensed-Italic.woff2") format("woff2"),
//     url("/fonts/hinted-DejaVuSerifCondensed-Italic.woff") format("woff"),
//     url("/fonts/hinted-DejaVuSerifCondensed-Italic.ttf") format("truetype"),
//     url("/fonts/hinted-DejaVuSerifCondensed-Italic.svg#DejaVuSerifCondensed-Italic") format("svg");
//   font-weight: normal;
//   font-style: italic;
// }
